<!-- 集团预售券经营报表 -->
<template>
  <section class="cont couponsDetails">
    <Breadcrumb :crumbs="crumbs"/>

    <el-row class="content-box">
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="right-btn">
            <el-button class="bg-gradient" type="primary" @click="back"><span>返回</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" v-loading="loading">
          <el-table-column type="index" width="60" label="序号"></el-table-column>
          <el-table-column prop="hotelName" label="门店名称" min-width="30"></el-table-column>
          <el-table-column label="门店销售额" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.orderTotalPrice&&scope.row.orderTotalPrice.toFixed(2) || '0.00' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="门店实际收入" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.actualAmount&&scope.row.actualAmount.toFixed(2) || '0.00' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="门店预售券销售数" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.totalOrderCount || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="门店预售券预售种类" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.saleCount || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="购买人数" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.todayOrderCount || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="平均价格" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.averageHousePrice&&scope.row.averageHousePrice.toFixed(2) || '0.00' }}</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import {mapState} from "vuex";
import { getHotelStatisticsCouponMyself } from "@/api/GroupManagement/groupManagement";
export default {
  data(){
    return{
      crumbs: new Map([
        ['集团管理'], ['统计报表'], ['集团预售券经营报表'],['查看详情']
      ]),
      tableData:[],        // 表格数据
      loading: true,       // 表格加载效果
      total: 0,            // 用户列表总条目数
      page: 1,             // 当前页 默认第一页
      limit:10,            // 每页显示数
    }
  },
  computed:{
    ...mapState(['hotelInfo']),
  },
  mounted(){
    this.getData();
  },
  methods:{
    // 请求表格数据
    getData(){
      let params = {
        limit:this.limit,
        page:this.page,
      }
      let data = {
        beginDate:this.$route.query.date,
        endDate:this.$route.query.date,
        companyId:this.hotelInfo.storeId        // 集团ID
      }
      getHotelStatisticsCouponMyself(params,data).then(({ success, records, total }) => {
        if (success) {
          let arr = [];
          for (let index = 0; index < records.length; index++) {
            if (records[index].orders) {
              arr.unshift({...records[index].orders,"hotelName":records[index].hotelName});
            } else {
              arr.push({"hotelName":records[index].hotelName});
            }
          }
          this.tableData = arr;
          this.total = total;
          this.loading = false;
        }
      })
    },
    // 返回
    back(){
      this.$router.push({path: `/group/couponsOperatingReport`})
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getData();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getData();
    },
  }
}
</script>